.table {
  width: 100%;
  max-width: 960px;
  margin: 4em auto;
  padding: .75em;
  border-spacing: 1px;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
}
thead {
  background-color: #102027;
  color: #fff;
  letter-spacing: .1em;
}
th {
  padding: 10px 30px;
}
td {
  background: lightgray;
  padding: 7px 30px;
}

.t-body {
  background: lightblue;
}

.t-tr {
  background-color: rgb(63, 148, 151) !important;
}

tr.t-tr td {
  padding: 0px auto;
}