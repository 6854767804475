/* Page styles */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700");

* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #efefef;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.errorBoundary {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
header {
  flex-shrink: 0;
  margin-top: 2rem;
  background-color: inherit;
}

.horizontal_bar {
  display: inline-block;
  position: absolute;
  width: 50px;
  left: 70px;
  top: 47px;
  height: 3px;
  background-color: #381c08;
}

.logo {
  display: block;
  position: relative;
  text-decoration: none;
  background-color: transparent;
  border-radius: 50%;
  margin-top: -20px;
  margin-left: 10px;
}
.logo div {
  display: inline-block;
  position: absolute;
  height: 17px;
  width: 90px;
  color: #183a5a;
  left: 70px;
  top: 23px;
}
.logo div img {
  width: 100%;
  height: 100%;
}

img#brand {
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 50%;
  padding: 5px;
  background-color: #efefef;
}

.flash-msg-close {
  padding-left: 10px;
  outline: none;
  float: right;
  background: inherit;
  color: inherit;
  font-size: 1.1rem;
  cursor: pointer;
}
.alert-container {
  display: inline;
  /*
  align-items: center;
  width: 94%;
  max-width: 1024px;
  margin: 2rem auto 2rem;
*/
}
.alert-success {
  /*  background: rgb(208, 239, 208);
  padding: 5px 12px;  */
  margin: 30px;
  color: #049004;
  font-size: 1.09rem;
  text-align: left;
  /*
  border-radius: 3px;
  border: 1px solid rgba(137, 181, 137, 0.7); */
}
.alert-error {
  background: #f5d2d2;
  padding: 5px 12px;
  color: #b57777;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #b57777;
}
.alert-danger {
  background: rgb(215, 223, 172);
  padding: 5px 12px;
  color: rgb(90, 100, 73);
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgba(97, 100, 73, 0.7);
}
.error-auth {
  background: #f3d9d9;
  margin-bottom: 20px;
  padding: 5px 12px;
  color: #a95656;
  border-radius: 3px;
  border: 1px solid #c19c9c;
  font-size: 0.8rem;
  text-align: center;
}
.main-content {
  flex: 1 0 auto;
  margin-top: 4rem;
}

.menu-icon {
  width: 45px;
  height: 40px;
  padding: 5px;
  position: absolute;
  right: 25px;
  top: 18px;
  cursor: pointer;
}
.bar {
  display: block;
  height: 3px;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate3d(50%, -50%, 0);
  background-color: #381c08;
  cursor: pointer;
  /* transition: .5s all linear .5s; */
}
.bar:before,
.bar:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 3px;
  right: 0;
  background-color: #381c08;
  cursor: pointer;
  transition: 0.3s linear;
}
.bar:before {
  top: -10px;
}
.bar:after {
  top: 10px;
}
.bar.open {
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.bar.open:before {
  top: 0;
  transform: rotate(45deg);
  cursor: pointer;
}

.bar.open:after {
  top: 0;
  transform: rotate(-45deg);
  cursor: pointer;
}
.menu-wrapper {
  display: display;
  background: #ebe0d6;
  position: absolute;
  right: 25px;
  top: 60px;
  border-radius: 3px;
  opacity: 1;
  transition: all 0.3s linear;
  height: 0px;
  overflow: hidden;
}
.menu-wrapper.open {
  display: block !important;
  opacity: 1;
  padding: 15px;
  height: auto;
  overflow: auto;
}

/* .auth-wrapper {
	margin-bottom: 15px;
} */
.auth {
  display: block;
  right: 20px;
  padding: 7px 35px;
  background-color: #363636;
  color: #fff;
  margin: 5px 10px;
  outline: none;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  text-decoration: none;
  letter-spacing: 0.07rem;
  line-height: 1.2rem;
  cursor: pointer;
}
.auth:nth-child(2n) {
  margin-top: 10px;
}
.auth.logout {
  background: #ad4c4c !important;
  text-align: center;
  width: 90%;
  margin-top: 15px;
}
.auth:first-child {
  background-color: #183a5a;
}
.menu-link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #183a5a;
  padding: 7px 5px;
  margin: auto 10px;
  border-top: 1px solid #4e280c;
}
.menu-link:last-child {
  margin-bottom: 10px;
  border-bottom: 1px solid #4e280c;
}
.menu-link:active {
  color: #4e280c;
}

main {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  width: 90%;
  max-width: 840px;
  margin: 2rem auto 4rem;
  background-color: white;
}

.home-img {
  width: 45%;
  float: left;
  margin-right: 4rem;
  /* background: lightgreen; */
}

.home-img img {
  float: right;
  width: 100%;
  /* min-width: 200px;
  max-width: 270px; */
  height: 70%;
  min-height: 340px;
  max-height: 440px;
  border: none;
  border-radius: 0px;
}
.home-txt {
  /* width: 60%; */
  /* padding: 2rem; */
  justify-content: center;
  align-content: center;
  text-align: left;
  /* background: lightyellow; */
  border-radius: 50%;
}
.home-txt h1 {
  /* font-family: "Orbitron", sans-serif; */
  font-size: 2.2em;
  line-height: 2rem;
  font-weight: 700;
  padding-top: 4.5%;
  padding-bottom: 30px;
}
.home-txt-p2 {
  padding: 10px 0px;
  margin: 10px auto 30px;
  color: #868484;
  font-size: 0.9rem;
  font-weight: bolder;
  letter-spacing: 0.5px;
}

.description {
  display: block;
  /* font-family: "Orbitron", sans-serif; */
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: 600;
  /* color: rgba(0,0,0,0.7); */
  letter-spacing: 0.12em;
}

.description span {
  padding-bottom: 10px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bolder;
}

.contact {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2em;
  padding: 15px 25px;
  /* margin: 40px 0px 30px; */
  border-radius: 25px;
  top: 0;
  background-color: #363636;
  color: floralwhite;
  /* font-family: "Orbitron", sans-serif; */
  font-size: 0.75rem;
  font-weight: 700;
  box-shadow: 0px 5px 30px 4px rgba(0, 0, 0, 0.3);
  transition: all 500ms;
}
.contact:hover {
  cursor: pointer;
  border-radius: 40px;
  background-color: #000;
}
.contact:active {
  top: 2px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  background-color: #5f5d5d;
}
footer {
  flex-shrink: 0;
  margin: auto;
  width: calc(100% - 40px);
  text-align: center;
  border-top: 1px solid rgba(179, 178, 163, 0.25);
  color: #8e8e8e;
  padding: 15px 5%;
  font-size: 0.75rem !important;
}
footer > a {
  text-decoration: none;
  color: inherit;
}
footer > a:hover {
  text-decoration: underline;
}

/*modal*/

.contact-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: -100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  transition: top 0.3s linear;
}

.modal-open {
  display: block !important;
  top: 0 !important;
}
.modal-link-close {
  display: inline-block;
  padding: 5px 20px;
  font-size: 0.9rem;
  letter-spacing: 1px;
  background: #633513;
  color: #fff;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.modal-view {
  width: 650px;
  max-width: 94%;
  height: 600px;
  max-height: 94%;
  margin: auto;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0px 0px 50px 5px rgba(66, 64, 64, 0);
  /* overflow-x: hidden; */
  overflow-y: auto;
  opacity: 1;
}

#contact-heading {
  display: block;
  margin-top: 40px;
  margin-bottom: 60px;
  color: #363636 !important;
  box-sizing: border-box;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
}
.contact-info {
  margin-bottom: 20px;
  padding-bottom: 7px;
  padding-left: 7px;
  width: 100%;
  outline: none;
  background: #eee;
  border-bottom: 2px solid rgba(93, 72, 57, 0.5);
  border-radius: 0;
  font-size: 1.2em;
  text-align: left;
}
.contact-info:focus {
  border-bottom: 2px solid saddlebrown;
}
/* input:focus,
input,
textarea:focus,
textarea {
  color: saddlebrown !important;
} */

textarea.contact-info {
  padding-top: 40px;
  margin-top: 70px;
  /* height: 70px; */
  overflow-y: auto;
  resize: none;
}
/* ============ FORM ERRORS ========= */
.error-span {
  display: block;
  color: rgb(216, 33, 33);
  font-size: 0.85rem;
  font-weight: bold;
  padding-bottom: 5px;
}
.error-border {
  border: 1px dashed rgb(216, 33, 33);
}

.captcha-wrapper {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
button.contact-info {
  background-color: #000;
  border-bottom: none;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 1.2em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
  transition: background-color 500ms;
}
button.contact-info:hover {
  cursor: pointer;
  background-color: #363636;
}
.relative {
  position: relative;
}
#close-btn {
  width: 45px;
  height: 40px;
  border-radius: 0 0 0 5px;
  position: absolute;
  background-color: #ac0f0f;
  color: #faf5f5;
  font-size: 3.5rem;
  font-weight: normal;
  line-height: 40px;
  text-align: center;
  pointer-events: auto;
  top: 0px;
  right: 0px;
  opacity: 0.2;
  -webkit-transition: opacity 500ms;
  transition: opacity 1s;
}
#close-btn:hover {
  cursor: pointer;
  opacity: 1;
}

/* Contact page */
.result-txt {
  flex: 1 0 auto;
  width: 90%;
  margin: 8rem auto 2rem;
  line-height: 1.5em;
  font-size: 1.2rem;
  text-align: center;
}

.result-link {
  text-decoration: none;
  font-weight: bold;
  color: #633513;
}

/* ##############################################################################
			ERROR
############################################################################## */

#error-page {
  background-color: saddlebrown;
  position: fixed !important;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
}

#error-inner {
  margin: auto;
  height: auto;
  max-height: 600px;
  top: 50%;
  transform: translate3d(0, 30%, 0);
}
#error-inner h1 {
  text-transform: uppercase;
  color: white;
  margin-top: 20px;
  padding: 20px;
  font-size: 2em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
}

#error-inner p {
  margin-top: 2em;
}
#error-inner p > a {
  font-size: 1.7em;
  color: white;
  text-decoration: none;
}

.pesan-error {
  width: 200px;
  height: 200px;
  margin: 30px auto 40px;
  background: #ffc754;
  color: #fff;
  font-size: 100px;
  line-height: 200px;
  border-radius: 95px 95px 14px 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  position: relative;
  animation: floating 2s infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* #########################################################################
																DASHBOARD
   ######################################################################## */
.dashboard-container {
  margin: 50px auto 30px;
  width: 90%;
  max-width: 800px;
  text-align: center;
}

.dashboard-label {
  display: inline-block;
  margin: 15px;
  height: 200px;
  width: calc(50% - 40px);
  border-radius: 15px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(-63%, #630),
    color-stop(222%, #fff)
  );
  background: linear-gradient(180deg, #630 -63%, #fff 222%);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  color: #eee;
  text-transform: uppercase;
  text-decoration: none;
  /* font-size: 1.3rem; */
  font-weight: 700;
  letter-spacing: 1px;
  /* line-height: 200px; */
}
.dashboard-alert {
  width: 90%;
  max-width: 800px;
  margin: auto;
  text-align: right;
  font-size: 0.8rem;
  padding-right: 25px;
}
a.dashboard-label > div:first-child {
  line-height: 130px;
  height: 130px;
}
a.dashboard-label > div:last-child {
  background: #ffffff;
  height: 70px;
  color: grey;
  padding: 20px;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
/* #########################################################################
																APPLICATION
   ######################################################################## */

/* FORM */

.table-wrapper.jb-add {
  max-width: 760px !important;
  padding: 20px 10px !important;
}
form.job-add-form {
  display: block;
  font-size: 1.4rem;
}
label.job-add {
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: bolder;
}
label.job-add > input,
label.job-add > textarea {
  background: #eeeeee;
  font-family: inherit;
  width: 100%;
  font-size: 1rem;
  padding: 7px 10px;
  letter-spacing: 1px;
  border-radius: 3px;
  outline: none;
}
textarea.desc {
  height: 150px;
}
button.job-add {
  outline: none;
  background-color: #4e1e1e;
  padding: 10px 30px;
  border-radius: 3px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.12rem;
  font-weight: bold;
  font-size: 0.9rem;
  color: white;
}

/* TABLE */

.table-wrapper {
  display: block;
  max-width: 1024px;
  width: 94%;
  margin: 0rem auto 2.5rem;
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.03);
  padding: 10px;
}
.container-heading {
  text-align: center;
  padding: 2rem 1.5rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.form-wrapper {
  display: block;
  margin: 0.75rem;
}
.form-wrapper > h2 {
  padding: 1rem 0 2rem;
  text-transform: uppercase;
  text-align: center;
}
.table-container {
  padding: 0px;
  background-color: #fff;
  border: 1px solid lightgrey;
}
.jb-wrapper {
  text-transform: capitalize;
  cursor: pointer;
}
.jb-wrapper > li {
  padding-right: 10px;
  overflow-x: hidden !important;
  font-size: 0.9rem !important;
}
.jb-index {
  text-align: center;
}
ul {
  list-style: none;
  margin: 1px;
}
.tb-header > ul {
  text-transform: uppercase;
  padding: 0 5px;
  font-size: 1rem;
  background-color: lightslategray;
  color: #ffffff;
}
.jb-hd > li {
  font-size: 1rem !important;
}

.loading {
  font-size: 1.4rem;
  text-align: center;
  padding: 20px;
}

ul.job-app-box:nth-child(2n) {
  background-color: #eee;
}
ul.job-app-box:nth-child(2n) > ul {
  border-top: 1px solid #fff !important;
}

li {
  display: inline-block;
  width: 15%;
  padding-top: 7px;
  padding-bottom: 7px;
  font-family: inherit;
  font-size: 1.1rem;
}
li:first-child {
  width: 6%;
}
/* li:last-child {
} */
li > span {
  text-align: center;
  cursor: pointer;
  opacity: 0.65;
}
li > span:first-child {
  padding-right: 10px;
  padding-left: 5px;
}
li > span:last-child {
  padding-left: 10px;
  padding-right: 5px;
}

ul.desc-content {
  padding: 15px 25px;
  display: none;
  border-top: 1px solid #eee !important;
  font-size: 0.85rem;
}
.desc-content.on {
  display: block !important;
}
ul.desc-content > h2 {
  padding: 15px 10px 20px;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
}
/* =================  JOB EDIT  ==================== */
.jb-edit > span {
  font-weight: bold;
}
.jb-edit-prop {
  display: block;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.07);
  width: 100%;
  font-size: 0.9rem;
  color: inherit;
  outline: none !important;
}
.jb-edit-prop.desc {
  height: 150px;
}
button.job-edit {
  outline: none;
  background-color: #381c08;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 9px 25px;
  border-radius: 3px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.12rem;
  font-weight: bold;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
}

/* ===================== REGISTER PAGE ========================== */

.table-wrapper.signup {
  max-width: 600px !important;
  padding: 30px !important;
}

/* ============================= LIST OF SIGNEDUPS =========================== */

table {
  margin: auto;
}

h2.signedup {
  margin: auto;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 17px;
}

table td {
  transition: all 0.5s;
}

/* Table */
.data-table {
  max-width: 840px;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 12px;
  border: 1px solid #aacad4 !important;
}

.data-table th,
.data-table td {
  border-bottom: 1px solid #aacad4;
  padding: 5px 10px;
}
/* .data-table caption {
  margin: 7px;
} */
td button {
  /* padding: 5px 10px; */
  margin-left: 10px;
  /* letter-spacing: 1px; */
  border-radius: 25px;
  background: inherit;
  color: #633513;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
td button:hover {
  text-decoration: underline;
}
td span {
  cursor: pointer;
  font-size: 15px;
}
/* Table Header */
.data-table thead th {
  background-color: #3d6e96;
  color: #ffffff;
  border-color: #6ea1cc !important;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  font-size: 0.7rem;
}

/* Table Body */
.data-table tbody tr td {
  color: #353535;
  background-color: #e3e3e3;
  text-align: left;
}

/* .data-table tbody tr td {
  text-align: left;
} */

/* .data-table tbody tr:nth-child(even) {
  background-color: #fff;
}
.data-table tbody tr td:nth-child(even) {
  background-color: #fff;
} */

/* .data-table tr:hover td {
  background-color: #f1f1e7;
  border-color: #ffff0f;
} */

/* Table Footer */
/* .data-table tfoot th {
  background-color: #e5f5ff;
  text-align: left;
}
.data-table tfoot th:first-child {
  text-align: left;
}
.data-table tbody td:empty {
  background-color: #ffcccc;
} */

td:last-child {
  padding: 5px 0px;
}

/* ============================= EXPENSES =========================== */
.xpense-container {
  margin-bottom: 35px;
}
.xps-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.xps-header {
  display: flex;
}
.xps-header-title {
  display: block;
}
.sort-box {
  display: inline-block;
  position: relative;
  color: #ccc;
  font-size: 0.3rem;
  margin-left: 15px;
  /* float: right; */
}

.sort-box i {
  display: block;
  width: 100%;
}
.sort-box i:first-child {
  transform: translateY(1.5px) rotate(-90deg);
}

.sort-box i:last-child {
  margin-top: 2.2px;
  margin-left: -0.5px;
  transform: translateY(1.5px) rotate(90deg);
}
.xpense {
  max-width: 840px;
  margin: 40px auto 0px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  text-align: center;
  font-size: 0.9rem;
}
.xpense > div {
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}
.xpense > div > span {
  float: right;
  font-size: 25px;
  font-weight: bold;
  transform: translateY(-9px);
}
.xpense > form {
  width: 100%;
  margin-bottom: 20px;
}
.xpense-icon {
  font-size: 0.8rem;
  color: #858585;
}
.xpense-icon i {
  padding: 0px 10px;
  font-weight: bold;
  color: #606060;
}
.xpense-update {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.xpense-form-label {
  display: inline-block;
  margin-right: 10px;
  text-align: left;
}
.hide {
  display: none !important;
}

.monthly-expense-wrapper {
  width: 94%;
  max-width: 840px;
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
}
.monthly-expense-wrapper > ul:first-child {
  margin-bottom: 10px;
}
.monthly-expense {
  display: block;
  margin: 0;
  font-size: 0.7rem;
  padding: 0px;
  color: inherit;
  background-color: inherit;
}
.monthly-expense:hover {
  cursor: pointer;
}

.xpense-update-component > label:first-child {
  padding-top: 20px;
}
/* or ...label:nth-last-child(1) */
.xpense-update-component > label:last-child {
  padding-bottom: 20px !important;
}
.xpense-update-label {
  display: block;
  color: #4e280c;
  padding: 7px 20px;
  text-align: left;
}
.xpense-update-label > input,
.xpense-update-label > select,
.xpense-update-label > textarea {
  padding: 4px 7px;
  display: block;
  width: 100%;
}

.xpense-form-label > span,
.xpense-update-label > span {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 3px;
}
.xpense-form-label input,
.xpense-form-label select {
  display: inline-block;
  width: 130px;
  padding: 5px 15px !important;
  margin-top: 3px;
  font-size: 1rem;
  border-radius: 4px;
  background: #e3e3e3;
}
.xpense-form-btn {
  margin-top: 10px;
  font-weight: 600;
  background-color: #4e280c;
  font-size: 0.8rem;
  letter-spacing: 1px;
  color: #fff;
  padding: 7px 30px;
  border-radius: 5px;
}

.xpense-update-btn {
  width: 50%;
  padding: 10px;
  border-radius: 0px;
  border-top: 1px solid #ccc;
  float: left;
  font-weight: 600;
  background-color: inherit;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-left: 0px !important;
}

.xpense-update-btn:hover {
  opacity: 0.8;
  text-decoration: none;
}
.noaction {
  background: inherit;
  margin-left: 0 !important;
  border-right: 1px solid #ccc;
}
.xpense-edit {
  display: block;
  width: 100%;
  background-color: yellow;
}
.xpense-update-component {
  /* margin: 15px; */
  display: block;
  width: 90%;
  max-width: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  /* text-align: left; */
  padding: 0px !important;
  background: inherit;
  border: none !important;
  overflow-y: auto;
}

.capitalize {
  text-transform: capitalize !important;
}
.active {
  background-color: grey !important;
  color: white !important;
}
.pg-footer-wrapper {
  max-width: 520px;
  margin: auto;
  text-align: center;
}
.pg-footer-wrapper.header {
  text-align: right !important;
}
.pagination-year {
  width: auto !important;
  margin: auto auto 20px;
  border-left: 1px solid rgba(128, 128, 128, 0.65);
  border-right: 1px solid rgba(128, 128, 128, 0.65);
  padding: 0px 7px;
  font-size: 0.9rem;
  font-weight: bolder;
  text-decoration: none;
  text-align: left;
  border-right: 1px solid inherit;
  cursor: pointer;
}
.pagination-year:hover {
  color: #3d6e96;
}
.active-year {
  color: #3d6e96;
}
.pg-footer-content {
  width: auto !important;
  margin: auto;
  padding: 7px;
  font-size: 0.9rem;
  font-weight: bolder;
  text-decoration: none;
  text-align: left;
  border-right: 1px solid inherit;
  cursor: pointer;
}
.pg-footer-content:hover {
  background-color: grey !important;
  color: #fff;
}
li.pg-footer-content span {
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  text-align: left;
  padding: 0px;
}
.pg-footer-link {
  text-decoration: none !important;
}
/* ##################################### MEDIA QUERIES ######################################################## */

@media screen and (max-width: 860px) {
  .main-content {
    margin-top: 3rem;
  }
  .description {
    font-size: 1em;
  }
  li {
    width: 23%;
  }
  .jb-skills,
  .jb-feedback {
    display: none !important;
  }

  .xpense {
    max-width: 70%;
  }
  .xpense > form,
  .xpense > div {
    padding: 20px;
    margin: auto;
  }
  .xpense-update-label,
  .xpense-form-label {
    display: block;
    width: 100%;
  }
  .xpense-update-label input,
  .xpense-update-label textarea,
  .xpense-form-label input,
  .xpense-update-label select {
    display: block;
    width: 100%;
    padding: 7px !important;
    border-radius: 3px;
  }
  .xpense-form-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 560px) {
  header {
    /* margin-bottom: 3.5rem; */
    margin-bottom: 2rem;
  }
  .logo {
    margin-top: -25px;
    margin-left: 0px;
  }
  .menu-icon,
  .bar.open,
  .menu-wrapper {
    right: 15px;
  }
  main {
    margin-top: 1rem;
  }

  .h2.signedup {
    padding: 0px 20px 20px !important;
  }
  .dashboard-container {
    margin: 0px auto 30px;
  }
  .dashboard-label {
    display: block;
    width: calc(100% - 20px);
    margin: 20px 10px;
  }

  .home-img,
  .home-txt {
    flex: 1 100%;
  }
  .home-img {
    flex: 1 100%;
    margin: 0;
  }
  .home-img img {
    float: none;
    max-height: 370px;
  }
  .home-txt {
    flex: 1 100%;
    padding: 3rem 2rem;
  }
  .modal-view {
    overflow-x: hidden;
    margin: auto;
    padding: 20px;
  }
  .result-txt {
    width: 90%;
    margin-top: 4rem;
    font-size: 1em;
  }
  .xpense {
    max-width: 90%;
    margin: 0 auto;
  }
  .table-wrapper {
    margin: 0 auto 3rem;
  }
  .jb-wrapper {
    padding: 0px 5px !important;
  }
  .jb-wrapper > .jb-title {
    margin-left: 12px;
  }
  .job-add-form {
    display: block !important;
  }

  ul.desc-content {
    font-size: 0.9rem;
    padding: 10px;
  }

  /* SIGN UP & NEW JOB */
  .table-wrapper.signup {
    padding: 20px 10px !important;
  }

  /* APPLICATION */
  button.job-add {
    display: block !important;
    width: 100%;
    margin: 20px auto 15px;
    font-size: 1rem;
    cursor: pointer;
  }
  .jb-hd {
    padding-right: 10px;
  }
  li:first-child {
    width: auto;
  }
  .jb-hd > li {
    font-style: normal !important;
    font-weight: bolder !important;
    font-size: 0.9rem;
  }
  .jb-hd > li:first-child {
    padding-left: 0px;
  }
  .jb-hd .jb-icons {
    margin-right: 20px;
  }
  .jb-company {
    display: inline-block;
    width: 60%;
  }
  .jb-company {
    margin-left: 10px;
    padding-left: 10px;
    font-weight: bolder;
  }
  .jb-index {
    padding-left: 5px;
  }
  .jb-icons {
    width: auto;
    float: right;
  }
  .jb-icons > span {
    padding: 5px !important;
    margin: 0px 5px;
  }
  .jb-title,
  .jb-skills,
  .jb-applied-on,
  .jb-feedback {
    display: none !important;
  }
  .job-edit {
    font-size: 0.8rem !important;
    padding: 9px 15px !important;
    letter-spacing: 0.1rem !important;
  }

  /* hide some block of inputs */
  .hide-on-sm {
    display: none;
  }
}
